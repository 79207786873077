<template>
  <div>
    <div class="salesforce-config-container">
      <VasionSalesForceIcon />
      <h3>SalesForce</h3>
      <VasionButton
        id="configure-salesforce-btn"
        :isFullWidth="true"
        :classProp="'primary'"
        @vasionButtonClicked="showSalesForceConnectionModal = true"
      >
        {{ salesForceConnectionButtonText }}
      </VasionButton>
      <VasionSnackbar
        :showSnackbarBool.sync="showSnackbar"
        :snackbarImage="showSnackbarImage"
        :snackbarSubTitle="snackbarSubTitle"
        :snackbarTitle="snackbarTitle"
      />
    </div>
    <div>
      <VasionGeneralModal
        :rejectButtonText="'Cancel'"
        :confirmButtonText="'Disconnect'"
        :message="disconnectConfirmationMessage"
        :leftAlignMessageText="true"
        :sync="showConfirmDisconnectModal"
        :modalTitle="'Disconnect SalesForce'"
        :modalType="'message'"
        :showTitleAndMessage="true"
        @confirmButtonClick="deleteSalesForceConnection"
        @noButtonClick="showConfirmDisconnectModal = false"
      />
      <VasionGeneralModal
        rejectButtonText="Cancel"
        confirmButtonText="Save"
        :sync="showSalesForceConnectionModal && !showConfirmDisconnectModal"
        modalTitle="SalesForce Configuration"
        modalType="slot"
        :hideButtons="true"
        @confirmButtonClick="saveButtonClicked"
        @noButtonClick="cancelButtonClick"
      >
        <div class="input-fields">
          <VasionInput
            class="input-field"
            v-model="userName"
            inputType="top-white"
            :isDisabled="isLoading"
            :required="true"
            :title="'USERNAME'"
            @input="isDirty = true"   
          />
          <VasionInput
            class="input-field"
            v-model="password"
            inputType="top-white"
            :isDisabled="isLoading"
            :required="true"
            :title="'PASSWORD (INCLUDING SECURITY TOKEN)'"
            :specialType="'password'"
            @input="isDirty = true"     
          />
          <VasionInput
            class="input-field"
            v-model="apiUrl"
            inputType="top-white"
            :isDisabled="isLoading"
            :required="true"
            :title="'API URL'"
            @input="isDirty = true"   
          />    
        </div>
        <div class="salesforce-modal-footer buttons-group">
          <VasionButton
            class="button-left"
            v-if="isConnected"
            :classProp="'secondary'"
            @vasionButtonClicked="disconnectButtonClick"
          >
              {{ 'Disconnect' }}
            </VasionButton>
            <VasionButton
              :classProp="'secondary'"
              @vasionButtonClicked="cancelButtonClick"
            >
              {{ 'Cancel' }}
            </VasionButton>
            <VasionButton
              :isDisabled="!isDirty || !allFieldsHaveText || isLoading"
              :classProp="'primary'"
              @vasionButtonClicked="saveButtonClick"
            >
              {{ 'Save' }}
            </VasionButton>
          </div>
        </VasionGeneralModal>
    </div>
</div>
</template>

<script>
import { 
    getSalesforceConnectionInfo,
    saveSalesforceConnectionInfo,
    testSalesforceConnectionInfo,
    deleteSalesforceConnectionInfo
 } from '../../../store/apiModules/admin.api'

export default {
  name: 'SalesForceConnector',
  props: {

  },
  data: function () {
    return {
        apiUrl: '',
        isConnected: false,
        isDirty: false,
        isLoading: true,
        password: '',
        showConfirmDisconnectModal: false,
        showPassword: false,
        showSalesForceConnectionModal: false,
        showSnackbar: false,
        showSnackbarImage: false,
        snackbarSubTitle: '',
        snackbarTitle: '',
        userName: '',    
    }
  },
  watch: {
      showSalesForceConnectionModal: function () {
          if (!this.showSalesForceConnectionModal){
              this.showPassword = false;
              this.getConnectionSettings();
          }
      } 
  },
  async created() {
    this.getConnectionSettings();
  },
  computed: {
    allFieldsHaveText() {
        return this.userName.length > 0 && this.password.length > 0 && this.apiUrl.length > 0;
    },
    apiToken() { return this.$store.state.common.apiToken },
    disconnectConfirmationMessage() {
        return 'Are you sure you want to disconnect this SalesForce account? Any lookups associated with this account will no longer work once you disconnect.'
    },
    salesForceConnectionButtonText() {
        return this.isConnected ? 'Configure' : 'Connect'
    }
  },
  methods: {
    async saveButtonClick() {
        this.isLoading = true;
        const payload = { 
            user: this.userName,
            password: this.password,
            url: this.apiUrl
        };

        if(!(await this.testConnection())){
            this.isLoading = false;
            this.showConnectionFailedMessage()
            return;
        }

        await saveSalesforceConnectionInfo(this.apiToken, payload);
        this.showConnectionSuccessMessage();
        this.showSalesForceConnectionModal = false;  
        this.isLoading = false;
    },
    cancelButtonClick() {
        this.showSalesForceConnectionModal = false;
    },
    disconnectButtonClick() {
        this.showConfirmDisconnectModal = true;
    },
    async deleteSalesForceConnection(){
        this.isLoading = true;
        await deleteSalesforceConnectionInfo(this.apiToken)
        this.isLoading = false;
        this.showConfirmDisconnectModal = false;
        this.showSalesForceConnectionModal = false;
        this.isConnected = false;
        this.showConnectionDeletedMessage();
    },
    async getConnectionSettings(){
        this.isLoading = true;
        
        let connectionInfo = await getSalesforceConnectionInfo(this.apiToken);
        this.apiUrl = connectionInfo.url;
        this.password = connectionInfo.password;
        this.userName = connectionInfo.user;

        if(connectionInfo.user.length > 0){
            await this.testConnection()
        }

        this.isLoading = false;
    },
    showConnectionFailedMessage(){
        this.snackbarTitle = 'Unable to Connect to SalesForce';
        this.snackbarSubTitle = 'Please Check Your Connection Settings';
        this.showSnackbar = true;
        this.showSnackbarImage = false;
    },
    showConnectionSuccessMessage(){
        this.snackbarTitle = 'Connection Succesful';
        this.snackbarSubTitle = 'Connected to SalesForce';
        this.showSnackbar = true;
        this.showSnackbarImage = true;
    },
    showConnectionDeletedMessage(){
        this.snackbarTitle = 'Success';
        this.snackbarSubTitle = 'Disconnected from SalesForce';
        this.showSnackbar = true;
        this.showSnackbarImage = true;
    },
    async testConnection() {
        const payload = { 
            user: this.userName,
            password: this.password,
            url: this.apiUrl
        };
        const testConnectionResponse = await testSalesforceConnectionInfo(this.apiToken, payload);
        const isSFConnected = testConnectionResponse?.Value?.toString().trim().toLowerCase() === 'true'
        this.isConnected = isSFConnected
        return isSFConnected
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

    .input-fields {
        width: 50vw;
        max-width: 900px;
        height: 300px;
        max-height: 700px;
        border: none;
        overflow: auto;
        border-radius: 0%;
        margin: 8px 0;
    }
    .input-field{
        margin-top: 1.5rem;
    }
    .salesforce-config-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #configure-salesforce-btn {
        display: block;
        width: 150px;
    }
    .modal-title {
        @include SubtitleActive;
        font-size: 1.75em;
    }
    .overlay {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 20;
        cursor: auto;
        .modal-container {
        z-index: 1000;
        border-radius: 8px;
        background: #FFFFFF;
        padding: 25px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        animation: grow .30s;
        max-width: 100%;
        max-height: 100%;
        overflow-y: auto;
        .salesforce-modal {
            height: 100%;
            display: flex;
            flex-direction: column;
            margin: auto;
            .message {
            @include Text
            }
        }
        .salesforce-modal-footer {
            position: relative;
            bottom: 0;
            right: 0;
        }
        }
    }
    .buttons-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        
        .button-left{
        margin-right: auto;
        }
    }
    @keyframes grow {
        from {
            transform: scale(.5);
        }
        to {
            transform: scale(1);
        }
    }
</style>
