<template>

<div>
  <div class="vasion-page-title software-page-title">
    Software
  </div>
  <div class="flex-container"> 
    <div class="flex-item">
      <SalesForceConnector />
    </div>
  </div>
</div>

</template>

<script>
import SalesForceConnector from './SalesForceConnector.vue'

export default {
  name: 'Software',
  components: {
    SalesForceConnector
  },
  data: function () {
    return {

    }
  },
  computed: {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>

.flex-container {
  display: flex;
  flex-wrap: wrap;
}
.flex-item {
  margin: 10px;
  padding: 20px;
  width: 300px;
  height: 300px;
}
.software-page-title {
  margin: 20px;
}

</style>
